import { useEffect, useState } from "react"
import InputSkeleton from "../../../common/Skeleton/InputSkeleton"
import { Link, useParams } from "react-router-dom"
import { gethoaDetails } from "../core/_requests";
import HoaAcknowledgement from "./HoaAcknowledgement";
import HoaGuideline from "./HoaGuideline";

function HoaDetails() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false)
    const [hoaDetails, setHoaDetails] = useState<any>("")
    useEffect(() => {
        const fetchHoaDetails = async () => {
            setLoading(true)
            try {
                const response: any = await gethoaDetails(id)
                if (response) {
                    setHoaDetails(response.data);
                }
            } catch (error) {
                setLoading(false)
            } finally {
                setLoading(false)
            }
        }
        fetchHoaDetails();
    }, [id])

    return (
        <>
            <div className='card mb-5 mb-xl-10' id='kt_hoa_details_view'>
                <div className='card-header cursor-pointer'>
                    <div className='card-title m-0 d-flex justify-content-between'>
                        <h3 className='fw-bolder m-0'>Detail HOA</h3>
                    </div>
                    <div className='card-title m-0'>
                        <Link to={"/hoa"} className='btn btn-light'><i className="bi bi-arrow-left-short fs-1"></i>Back</Link>
                    </div>
                </div>
                {loading ? <InputSkeleton rows={4} columns={2} height={30} /> :
                    <div className='card-body p-9'>
                        <div className='row'>
                            <div className='col-6'>
                                <div className='row mb-7'>
                                    <label className='col-lg-4 fw-bold text-muted'>Official Name</label>
                                    <div className='col-lg-8'>
                                        <span className='fw-bolder fs-6 text-dark'>{hoaDetails?.official_name}</span>
                                    </div>
                                </div>
                                <div className='row mb-7'>
                                    <label className='col-lg-4 fw-bold text-muted'>Email</label>
                                    <div className='col-lg-8 d-flex align-items-center'>
                                        <span className='fw-bolder fs-6 me-2'>
                                            {hoaDetails?.email}
                                        </span>
                                    </div>
                                </div>
                                <div className='row mb-7'>
                                    <label className='col-lg-4 fw-bold text-muted'>Web Address</label>
                                    <div className='col-lg-8 fv-row'>
                                        <span className='fw-bold fs-6'>{hoaDetails?.web_address}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='row mb-7'>
                                    <label className='col-lg-4 fw-bold text-muted'>Short Name</label>
                                    <div className='col-lg-8 fv-row'>
                                        <span className='fw-bold fs-6'>{hoaDetails?.short_name}</span>
                                    </div>
                                </div>
                                <div className='row mb-7'>
                                    <label className='col-lg-4 fw-bold text-muted'>Business Address</label>
                                    <div className='col-lg-8 fv-row'>
                                        <span className='fw-bold fs-6'>{hoaDetails?.business_address}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <HoaGuideline hoaDetails={hoaDetails} loading={loading} />
            <HoaAcknowledgement hoaDetails={hoaDetails} loading={loading} />
        </>
    )
}

export default HoaDetails