import { ID, Response } from '../../../../_metronic/helpers'
export type Board = {
    id?: ID
    official_name?: string
    email?: string
    business_address?: string

}

export type BoardQueryResponse = Response<Array<Board>>

export const initialBoard: Board = {
    official_name: '',
    email: '',
    business_address: '',

}


// response
export interface IBoardResponse {
    status: boolean;
    message: string;
    data: any;
    error: any;
}

export type IBoardCreate = {
    official_name: string
    email: string
    password: string
    business_address?: string
}
export const boardInitValues: IBoardCreate = {
    official_name: "",
    email: "",
    password: "",
    business_address: "",
}
export type IBoardUpdate = {
    id: string
    official_name: string
    email: string
    password: string
    business_address: string
}
export const boardUpdateInitValues: IBoardUpdate = {
    id: '',
    official_name: '',
    email: '',
    password: '',
    business_address: '',
}
