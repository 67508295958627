import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { ID, KTIcon, QUERIES } from '../../../../../../_metronic/helpers'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { Link } from 'react-router-dom'
import { deleteBoard } from '../../../core/_requests'

import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
type Props = {
  id: ID
}

const BoardActionsCell: FC<Props> = ({ id }) => {

  const { query } = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const deleteItem = useMutation(async (id: ID) => {
    
    const response = await deleteBoard(id);
    if (response) {
      toast.success("Board deleted successfully.");
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`]);
    }
    // try {
    //   const response = await deleteBoard(id);
    //   if (response) {
    //     toast.success("Board deleted successfully.");
    //     queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`]);
    //   }

    // } catch (error) {
    //   console.error("Failed to delete board:", error);
    //   toast.error("Failed to delete the board. Please try again.");
    // }
  });
  const handleDelete = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: "#3085d6",
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteItem.mutate(id)
      }
    })
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <Link to={`/board/${id}/edit`} className='menu-link px-3'>
            Edit
          </Link>
        </div>

        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            onClick={handleDelete}
          >
            Delete
          </a>
        </div>
      </div>
    </>
  )
}

export { BoardActionsCell }
