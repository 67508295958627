import axios, { AxiosResponse } from "axios";
import { IBoardCreate, IBoardResponse, IBoardUpdate } from "./_models";
import { BoardQueryResponse } from "../../board/core/_models";
import { ID } from "../../../../_metronic/helpers";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL
const BOARD_URL = `${API_URL}/admin/board`

const getBoards = (query: string): Promise<BoardQueryResponse> => {
    return axios
        .get(`${BOARD_URL}?${query}`)
        .then((d: AxiosResponse<BoardQueryResponse>) => d.data)
}
async function createBoard(payload: IBoardCreate): Promise<IBoardResponse | null> {
    try {
        const response: AxiosResponse<IBoardResponse> = await axios.post(BOARD_URL, payload);
        if (response && response.data.status == true) {
            return response.data;
        } else {
            toast.error("Failed to create board: " + response.data.error);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Server Error. Please try again');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}
async function getBoard(id: string | undefined): Promise<IBoardResponse | null> {
    try {
        const response: AxiosResponse<IBoardResponse> = await axios.get(`${BOARD_URL}/${id}`);
        if (response && response.data.status == true) {
            return response.data;
        } else {
            toast.error("Failed to get board: " + response.data.error);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Server Error. Please try again');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}
async function updateBoard(payload: IBoardUpdate): Promise<IBoardResponse | null> {
    try {
        const response: AxiosResponse<IBoardResponse> = await axios.patch(`${BOARD_URL}/${payload.id}`, payload);
        if (response && response.data.status == true) {
            return response.data;
        } else {
            toast.error("Failed to update board: " + response.data.error);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Server Error. Please try again');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}
async function deleteBoard(id: ID): Promise<IBoardResponse | null> {
    try {
        const response: AxiosResponse<IBoardResponse> = await axios.delete(`${BOARD_URL}/${id}`);
        if (response && response.data.status == true) {
            return response.data;
        } else {
            toast.error("Failed to delete board: " + response.data.error);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Server Error. Please try again');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}
export { getBoards, createBoard, getBoard, updateBoard, deleteBoard }