import clsx from "clsx";
import { FormikHelpers, useFormik } from "formik";
import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from 'yup'
import { IBoardCreate, boardInitValues as initialValues } from "./core/_models";
import { createBoard } from "./core/_requests";
import { toast } from "react-toastify";

const boardCreateSchema = Yup.object().shape({
    official_name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').email('Please enter valid email formate'),
    password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters long')
        .matches(/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/, 'Password must match the required pattern'),
    business_address: Yup.string().nullable('Business address is required'),
})
const BoardCreate: FC = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const navigate = useNavigate();

    const { handleSubmit, touched, errors, getFieldProps, isSubmitting, isValid } = useFormik<IBoardCreate>({
        initialValues: initialValues,
        validationSchema: boardCreateSchema,
        onSubmit: async (values: IBoardCreate, { setStatus, setSubmitting }: FormikHelpers<IBoardCreate>) => {
            setLoading(true)
            setError(null)
            try {
                const data: any = await createBoard(values);
                if (data.status) {
                    setLoading(false)
                    setSubmitting(false);
                    toast.success('Board created successfully.')
                    navigate('/board');
                } 
                // else {
                //     toast.error(data.error);
                //     setSubmitting(false)
                //     setLoading(false)
                // }
            } catch (error) {
                // toast.error('Failed to create board. Please try again.')
                // setStatus('Failed to create board')
                setSubmitting(false)
                setLoading(false)
            }
        },
    })
    return (

        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-target='#kt_board_create'
                aria-expanded='true'
                aria-controls='kt_board_create'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Create Board</h3>
                </div>
                <div className='card-title m-0'>
                    <Link to={"/board"} className='btn btn-light'><i className="bi bi-arrow-left-short fs-1"></i>Back</Link>
                </div>
            </div>
            <div id='kt_board_create'>
                <form onSubmit={handleSubmit} className='form'>
                    <div className='card-body border-top p-9'>
                        <div className="row mb-6">
                            <div className="col-lg-6 fv-row">
                                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Name</label>
                                <input
                                    type='text'
                                    placeholder='Name'
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': touched.official_name && errors.official_name },
                                        {
                                            'is-valid': touched.official_name && !errors.official_name,
                                        }
                                    )}
                                    {...getFieldProps("official_name")}
                                />

                                {touched.official_name && errors.official_name && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{errors.official_name}</div>
                                    </div>
                                )}
                              
                            </div>
                            <div className="col-lg-6 fv-row">
                                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Email</label>
                                <input
                                    type='email'
                                    placeholder='Email'
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': touched.email && errors.email },
                                        {
                                            'is-valid': touched.email && !errors.email,
                                        }
                                    )}
                                    {...getFieldProps("email")}
                                />
                                {touched.email && errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{errors.email}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="row mb-6">
                            <div className="col-lg-6 fv-row">
                                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Password</label>
                                <input
                                    type='password'
                                    placeholder='Password'
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': touched.password && errors.password },
                                        {
                                            'is-valid': touched.password && !errors.password,
                                        }
                                    )}
                                    {...getFieldProps("password")}
                                />
                                {touched.password && errors.password && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{errors.password}</div>
                                    </div>
                                )}
                            </div>

                            <div className="col-lg-6 fv-row">
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Business Address</label>
                                <input
                                    type='text'
                                    placeholder='Business Address'
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': touched.business_address && errors.business_address },
                                        {
                                            'is-valid': touched.business_address && !errors.business_address,
                                        }
                                    )}
                                    {...getFieldProps("business_address")}
                                />
                                {touched.business_address && errors.business_address && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{errors.business_address}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={isSubmitting || !isValid}>
                            {!loading && 'Create Board'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
export { BoardCreate }