import clsx from 'clsx'
import {FC} from 'react'
import { Hoa } from '../../../core/_models'
import { Link } from 'react-router-dom'

type Props = {
  user: Hoa
}

const HoaInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <Link to={`/hoa/${user.id}/details`} className='text-gray-800 text-hover-primary mb-1'>
        {user.official_name}
      </Link>
    </div>
  </div>
)

export { HoaInfoCell }
