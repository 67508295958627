import { ID, Response } from '../../../../_metronic/helpers'
export type Hoa = {
    id?: ID
    official_name?: string
    short_name?: string
    role?: string

}

export type HoaQueryResponse = Response<Array<Hoa>>

export const initialUser: Hoa = {
    official_name: '',
    short_name: '',
    role: '',

}


// response
export interface IHoaResponse {
    status: boolean;
    message: string;
    data: any;
    error: any;
}

export type IHoaCreate = {
    official_name: string
    short_name: string
    business_address: string
    email: string,
    password: string,
    web_address: string
    // self_managed: boolean
    management_company: string;
    management_company_website: string;
    documents_bucket: string,
    plan_tier: string,
    role: string,
    tncs: string
}
export const hoaInitValues: IHoaCreate = {
    official_name: "",
    short_name: "",
    business_address: "",
    email: "",
    password: "",
    web_address: "",
    // self_managed: true,
    management_company: "",
    management_company_website: "",
    documents_bucket: "",
    plan_tier: "",
    role: "manager",
    tncs: ""
}
export type IHoaUpdate = {
    id: string,
    official_name: string,
    short_name: string,
    business_address: string,
    email: string,
    password: string,
    web_address: string
    // self_managed: boolean,
    //management_company: string,
    //management_company_website: string,
    documents_bucket?: string,
    plan_tier?: string,
    role?: string,
    tncs?: string
}
export const hoaUpdateInitValues: IHoaUpdate = {
    id: "",
    official_name: "",
    short_name: "",
    business_address: "",
    email: "",
    password: "",
    web_address: "",
    // self_managed: true,
    //management_company: "",
    //management_company_website: "",
    documents_bucket: "",
    plan_tier: "",
    role: "manager",
    tncs: ""
}

