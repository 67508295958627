// declare module '@yaireo/tagify';
// declare module '@yaireo/tagify/dist/react.tagify';

export const REQUEST_COLOR_DATA = {
    "submitted":{
        color:"grey",
        title: "Submitted"
    },
    "in_review":{
        color:"warning",
        title: "Under Review"
    },
    "hoa_reviewed":{
        color:"primary",
        title: "Ready for Board Review"
    },
    "needs_discussion":{
        color:"primary",
        title: "Pending Discussion"
    },
    "approved":{
        color:"success",
        title: "Approved"
    },
    "cancelled":{
        color:"danger",
        title: "Cancelled"
    },
    "denied":{
        color:"danger",
        title: "Denied"
    },
};