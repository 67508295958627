import { Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { BoardCreate } from "./BoardCreate";
import { BoardListWrapper } from "./board-list/BoardList";
import { BoardEdit } from "./BoardEdit";

const boardBreadcrumbs: Array<PageLink> = [
    { title: 'Dashboard', path: '/dashboard', isSeparator: false, isActive: false },
    { title: '', path: '', isSeparator: true, isActive: false},
]
const boardCreateBreadcrumbs: Array<PageLink> = [
    { title: 'List Board', path: '/board', isSeparator: false, isActive: false, },
    { title: '', path: '', isSeparator: true, isActive: false, },
]
const boardEditBreadcrumbs: Array<PageLink> = [
    { title: 'List Board', path: '/board', isSeparator: false, isActive: false, },
    { title: '', path: '', isSeparator: true, isActive: false, },
]
const BoardPage = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    index
                    element={
                        <>
                            <PageTitle breadcrumbs={boardBreadcrumbs}>List Board</PageTitle>
                            <BoardListWrapper />
                        </>
                    }
                />
                <Route
                    path='create'
                    element={
                        <>
                            <PageTitle breadcrumbs={boardCreateBreadcrumbs}>Create New Board</PageTitle>
                            <BoardCreate />
                        </>
                    }
                />
                <Route
                    path=':id/edit'
                    element={
                        <>
                            <PageTitle breadcrumbs={boardEditBreadcrumbs}>Edit Board</PageTitle>
                            <BoardEdit />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}
export { BoardPage }
