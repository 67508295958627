import {FC} from 'react'
import { Board } from '../../../core/_models'
import { Link } from 'react-router-dom'

type Props = {
  board: Board
}

const BoardInfoCell: FC<Props> = ({ board }) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <Link to={`/board/${board.id}/edit`} className='text-gray-800 text-hover-primary mb-1'>
        {board.official_name}
      </Link>
    </div>
  </div>
)

export { BoardInfoCell }
