/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { currentUser }: any = useAuth()
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      {(currentUser.role === 'administrator' || currentUser.role === 'board' || currentUser.role === 'manager') && (
        <SidebarMenuItem
          to='/request'
          icon='bi bi-file-earmark-text'
          title={currentUser.role === 'manager' ? 'My Requests' : 'All Requests'}
        />
      )}
      {(currentUser.role == 'administrator' || currentUser.role == 'manager') &&
        <>
          <SidebarMenuItemWithSub
            to='/members'
            title='Member Management'
            icon='bi bi-people-fill'
          //fontIcon='bi-person'
          >
            <SidebarMenuItem to='/members' title='List Members' hasBullet={true} />

            {currentUser.role == 'manager' &&
              <>
                <SidebarMenuItem to='/members/create' title='Create Member' hasBullet={true} />
                <SidebarMenuItem to='/members/import' title='Import Members' hasBullet={true} />
              </>
            }
          </SidebarMenuItemWithSub>
        </>
      }
      {currentUser.role == 'administrator' &&
        <>
          <SidebarMenuItemWithSub
            to='/hoa'
            title='HOA Management'
            icon='bi bi-person-fill'
          >
            <SidebarMenuItem to='/hoa' title='List HOA' hasBullet={true} />
            <SidebarMenuItem to='/hoa/create' title='Create HOA' hasBullet={true} />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to='/board'
            title='Board Management'
            icon="bi bi-clipboard-check-fill"
            fontIcon='bi-person'
          >
            <SidebarMenuItem to='/board' title='List Board' hasBullet={true} />
            <SidebarMenuItem to='/board/create' title='Create Board' hasBullet={true} />
          </SidebarMenuItemWithSub>
        </>
      }

      {currentUser.role == 'manager' &&
        <SidebarMenuItemWithSub
          to='/guidelines'
          title='Guideline Management'
          icon='bi bi-list-check'
        >
          <SidebarMenuItem to='/guidelines' title='List Guidelines' hasBullet={true} />
          <SidebarMenuItem to='/guidelines/create' title='Create Guidelines' hasBullet={true} />
        </SidebarMenuItemWithSub>
      }
      {currentUser.role == 'manager' &&
        <SidebarMenuItemWithSub
          to='/acknowledgement'
          title='Acknowledgement'
          icon='bi bi-check-circle-fill'
        >
          <SidebarMenuItem to='/acknowledgement' title='List Acknowledgement' hasBullet={true} />
          <SidebarMenuItem to='/acknowledgement/create' className='mx-0' title='Create Acknowledgement' hasBullet={true} />
        </SidebarMenuItemWithSub>
      }
      
      {currentUser.role === 'administrator' &&
        <SidebarMenuItem
          to='/account/settings'
          icon='bi bi-gear-fill'
          title="Settings"
        />
      }
    </>
  )
}
export { SidebarMenuMain }
